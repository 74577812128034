// eslint-disable-next-line no-unused-vars
const ApprovalDataHeaders = (_this) => {
  return {
    countCells: 12,
    massive: [
      {
        dataField: 'subject',
        cols: 1,
        rows: 2,
        order: 0,
        text: 'Субъект РФ',
        // order: 0,
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        // template: (row) => {
        //   return row['sprOrgName'];
        // }
      },
      {
        dataField: 'nps',
        cols: 1,
        rows: 2,
        order: 1,
        text: 'НПС',
        // order: 0,
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        // template: (row) => {
        //   return row['sprOrgName'];
        // }
      },
      {
        dataField: 'xxx',
        cols: 3,
        rows: 1,
        order: 1,
        text: 'Предложение от субъекта РФ',
        sortable: true,
        typeCell: 'string',
        variable: false,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'yyy',
        cols: 3,
        rows: 1,
        order: 1,
        text: 'Предложение ФОИВа для субъекта РФ',
        sortable: true,
        typeCell: 'string',
        variable: false,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'approval',
        cols: 2,
        rows: 2,
        order: 9,
        text: 'Редактировать предложение, отправка на согласование с субъектом РФ',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'finalApprove',
        cols: 2,
        rows: 2,
        order: 10,
        text: 'Субъект РФ согласовал с профильным ФОИВом',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'placesOchno',
        cols: 1,
        rows: 1,
        order: 2,
        text: 'Кол-во очных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'placesOcnhoZaochno',
        cols: 1,
        rows: 1,
        order: 3,
        text: 'Кол-во очно-заочных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        headerClass: 'text-center',
      },
      {
        dataField: 'placesZaochno',
        cols: 1,
        rows: 1,
        order: 4,
        text: 'Кол-во заочных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'placesOchnoFoiv',
        cols: 1,
        rows: 1,
        order: 5,
        text: 'Кол-во очных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'placesOcnhoZaochnoFoiv',
        cols: 1,
        rows: 1,
        order: 6,
        text: 'Кол-во очно-заочных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        headerClass: 'text-center',
      },
      {
        dataField: 'placesZaochnoFoiv',
        cols: 1,
        rows: 1,
        order: 7,
        text: 'Кол-во заочных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
    ],
    regionMassive: [
      {
        dataField: 'nps',
        cols: 3,
        rows: 2,
        order: 0,
        text: 'НПС',
        // order: 0,
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        // template: (row) => {
        //   return row['sprOrgName'];
        // }
      },
      {
        dataField: 'xxx',
        cols: 3,
        rows: 1,
        order: 0,
        text: 'Предложение от субъекта РФ',
        // sortable: true,
        typeCell: 'string',
        variable: false,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'yyy',
        cols: 3,
        rows: 1,
        order: 0,
        text: 'Предложение ФОИВа для субъекта РФ',
        // sortable: true,
        typeCell: 'string',
        variable: false,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'approval',
        cols: 3,
        rows: 2,
        order: 7,
        text: 'Согласовано с профильным ФОИВом',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'placesOchno',
        cols: 1,
        rows: 1,
        order: 1,
        text: 'Кол-во очных мест',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'placesOcnhoZaochno',
        cols: 1,
        rows: 1,
        order: 2,
        text: 'Кол-во очно-заочных мест',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        headerClass: 'text-center',
      },
      {
        dataField: 'placesZaochno',
        cols: 1,
        rows: 1,
        order: 3,
        text: 'Кол-во заочных мест',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'placesOchnoFoiv',
        cols: 1,
        rows: 1,
        order: 4,
        text: 'Кол-во очных мест',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'placesOcnhoZaochnoFoiv',
        cols: 1,
        rows: 1,
        order: 5,
        text: 'Кол-во очно-заочных мест',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        headerClass: 'text-center',
      },
      {
        dataField: 'placesZaochnoFoiv',
        cols: 1,
        rows: 1,
        order: 6,
        text: 'Кол-во заочных мест',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
    ]
  };
}
export default ApprovalDataHeaders