<template>
  <div>
    <header class="section-header section-preview__header">
      <h2 class="section-header__title">
        Информация о введенных значениях Субъектами РФ
      </h2>
    </header>
    <wrapper-preview-category customClass="section-preview_lk">
      <GridTable
        :data-rows="approvalData"
        :headers="tableHeaders.regionMassive"
        :headers-for-rows="headersForRows"
        :num-cols="tableHeaders.countCells"
        :loading-data="loading"
        max-table-height="700px"
      >
        <template #nps="{ row }">
          <div>{{ `(${row.sprSpec.specCode}) ${row.sprSpec.specName}` }}</div>
        </template>
        <template #placesOchno="{ row }">
          <div>{{ row.placesOchno }}</div>
        </template>
        <template #placesOcnhoZaochno="{ row }">
          <div>{{ row.placesOcnhoZaochno }}</div>
        </template>
        <template #placesZaochno="{ row }">
          <div>{{ row.placesZaochno }}</div>
        </template>
        <template #placesOchnoFoiv="{ row }">
          <div>{{ row.foivOchno }}</div>
        </template>
        <template #placesOcnhoZaochnoFoiv="{ row }">
          <div>{{ row.foivOchnoZochno }}</div>
        </template>
        <template #placesZaochnoFoiv="{ row }">
          <div>{{ row.foivZaochno }}</div>
        </template>
        <template #approval="{ row }">
          <v-btn v-if="!row.regionAccept && row.foivAccept" color="primary" dark @click="accept(row)">
            Согласовать
            <v-icon dark right>mdi-check</v-icon>
          </v-btn>
          <v-btn v-if="row.regionAccept && row.foivAccept" color="info" dark>
            Согласовано
            <v-icon dark right>mdi-check-outline</v-icon>
          </v-btn>
        </template>
      </GridTable>
    </wrapper-preview-category>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import request from "@/services/request";
import WrapperPreviewCategory from "@/components/GridTable/dataFolder/WrapperPreviewCategory";
import GridTable from "@/components/GridTable/GridTable";
import ApprovalDataHeaders from "@/components/GridTable/dataFolder/ApprovalDataHeaders";

export default {
  name: "ApprovalRegion",
  components: {
    GridTable,
    WrapperPreviewCategory
  },
  data: () => ({
    loading: false,
    tableHeaders: [],
    approvalData: [],
    loadingAcceptAllEnterData: false
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles',
      isRegion: 'isRegion'
    }),
    headersForRows() {
      return this.tableHeaders.regionMassive.filter(el => el.variable).sort((a, b) => a.order - b.order)
    },
    
    // getCustomActionProps() {
    //   return {
    //     dataField: 'foivAccept',
    //     data: [
    //       {
    //         disable: true,
    //         value: false,
    //         icon: 'mdi-check',
    //         action: 'customAction',
    //         text: 'Принять',
    //         blocked: false,
    //         accessEdit: true,
    //         accessDelete: true,
    //       },
    //       {
    //         value: true,
    //         icon: 'mdi-check-outline',
    //         action: null,
    //         text: 'Значение принято',
    //         blocked: false,
    //         accessEdit: false,
    //         accessDelete: false,
    //       }]
    //   };
    // },
    
    // getBlocked() {
    //   if (this.getAuthUserData.userProfile === 2) {
    //     return true;
    //   }
    //   return !this.get_blockedEnterData;
    // },
    
    // dopPropsForDialog() {
    //   return {
    //     usl: {
    //       uslId: [1, 2, 3, 6, 9, 12]
    //     },
    //     fo: {
    //       foId: [1, 2, 3]
    //     },
    //     spec: {
    //       specFlKlasif: [5]
    //     }
    //   }
    // },
    
    // headers() {
    //   return tableHeaderFoivRegionsDeal
    // },
    
    // dataHeaders() {
    //   return (this.headers != null) ? ((Object.hasOwnProperty.call(this.headers, 'headers') && Object.hasOwnProperty.call(this.headers.headers, 'massive')) ? this.headers.headers.massive : []) : []
    // },
    
    // dataHeadersForDialog() {
    //   return (this.headers != null) ? ((Object.hasOwnProperty.call(this.headers, 'headers') && Object.hasOwnProperty.call(this.headers.headersForDialog, 'massive')) ? this.headers.headersForDialog.massive : []) : []
    // },
    
    // dataAccessDialog() {
    //   return (this.headers != null) ? (Object.hasOwnProperty.call(this.headers, 'headers') ? this.headers.accessDialog : false) : false
    // },
    
    // headersErrors() {
    //   return null
    // },
    
    // headersLoading() {
    //   return false
    // },
    
    // dataRows() {
    //   if (this.get_data_table?.forEach) {
    //     this.get_data_table.forEach((el) => {
    //       if (typeof el.sprRegion === 'object') {
    //         el.regionName = el.sprRegion.regionName;
    //       }
    //       if (typeof el.sprRegion === 'number') {
    //         let tmpRegion = this.get_spr_data_region.data.find((e) => {
    //           return e.regionId === el.sprRegion
    //         });
    //         el.regionName = tmpRegion?.regionName;
    //       }
    //       if (el.regionAccept === true) {
    //         el.regionAccept = "Субъект РФ принял предложения"
    //       } else {
    //         el.regionAccept = "Субъект РФ еще не принял предложения"
    //       }
    //     });
    //   }
    //   return {content: ((this.headers != null) ? this.get_data_table : []), totalElements: this.get_data_table.length}
    // },
    
    // getFounderId() {
    //   if (this.$route.query?.founderId) {
    //     return this.$route.query.founderId;
    //   }
    //   return this.getAuthUserData.founderId;
    // }
    
  },
  created() {
    this.tableHeaders = ApprovalDataHeaders(this);
    this.getAll();
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    
    // sendRequest(data) {
    //   console.log(data);
    //   this.updateDateForTable(new Object({
    //     data: {...data, founderId: this.getFounderId},
    //     link: this.headers.endPointForUpdate,
    //     id: data[this.idName]
    //   })).then(() => this.dataForDialog = null)
    //     .then(
    //       () => {
    //         this.getDataForTable({
    //           link: this.headers.endPointForSelect,
    //           allValues: true,
    //           pageNumber: this.idPageDefault,
    //           recordCount: this.numPageDefault,
    //           params: `?founderId=${this.getFounderId}`
    //         })
    //       }
    //     );
    //   this.dataForDialog = null
    // },
    
    // async checkAll() {
    //   this.loadingAcceptAllEnterData = true;
    //   const agree = confirm('Вы принимаеме значения указанные ФОИВом? После подтверждения отменить нельзя');
    //   if (agree) {
    //     const res = await request({
    //       endpoint: `/api/collection/main/update/byFoiv/acceptAll?founderId=${this.getFounderId}`,
    //       method: 'post',
    //     });
    //     if (res.status === 200) {
    //       this.getDataForTable({
    //         link: this.headers.endPointForSelect,
    //         allValues: true,
    //         pageNumber: this.idPageDefault,
    //         recordCount: this.numPageDefault,
    //         params: `?founderId=${this.getFounderId}`
    //       })
    //     } else {
    //       await this.addNotification({
    //         notificationStatus: 'error',
    //         notificationMessage: 'Ошибка выполнения команды',
    //         timeout: 5000
    //       })
    //     }
    //   }
    //   this.loadingAcceptAllEnterData = false;
    // },
    // async actionRow(obj) {
    //   this.loadingAccepEnterData = true;
    //   if (obj.typeAction === 'customAction' && obj.rowData.sprSpec.sprUsl.uslId !== 6) {
    //     let confirm_text = 'Принять указанные значения?';
    //     if (obj.rowData.foivAccept)
    //       confirm_text = 'Отменить принятие значений?';
    //     const agree = confirm(confirm_text);
    //     if (agree) {
    //       const res = await request({
    //         endpoint: `/api/collection/main/update/byFoiv/accept`,
    //         method: 'post',
    //         body: {
    //           regionAccept: false,
    //           foivAccept: !obj.rowData.foivAccept,
    //           collectionId: obj.collectionId
    //         }
    //       })
    //       if (res.status === 200) {
    //         this.getDataForTable({
    //           link: this.headers.endPointForSelect,
    //           allValues: true,
    //           pageNumber: this.idPageDefault,
    //           recordCount: this.numPageDefault,
    //           params: `?founderId=${this.getFounderId}`
    //         })
    //       } else {
    //         await this.addNotification({
    //           notificationStatus: 'error',
    //           notificationMessage: 'Ошибка выполнения команды',
    //           timeout: 5000
    //         })
    //       }
    //     }
    //   } else if (obj.typeAction === 'edit' && obj[this.idName] != null) {
    //     let ind = this.$scripts.TableJs.arraySearch(this.dataRows.content, obj[this.idName], this.idName)
    //     this.dataForDialog = ind > -1 ? this.dataRows.content[ind] : null
    //   }
    //   this.loadingAccepEnterData = false;
    // },
    async getAll() {
      this.loading = true;
      try {
        const res = await request({
          endpoint: `/api/collection/main/getAll?regionId=${this.getUser.regionID}`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          console.log('getAll успех');
          this.approvalData = res.data;
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async accept(row) {
      try {
        const res = await request({
          endpoint: `/api/collection/main/update/byFoiv/accept`,
          method: 'post',
          token: `${this.getAuthData.token}`,
          body: {
            "collectionId": row.collectionId,
            "regionId": row.sprRegion.regionId,
            "specId": row.sprSpec.specId,
            "placesOchno": row.placesOchno,
            "placesZaochno": row.placesZaochno,
            "placesOcnhoZaochno": row.placesOcnhoZaochno,
            "foivOchno": row.foivOchno,
            "foivOchnoZochno": row.foivOchnoZochno,
            "foivZaochno": row.foivZaochno,
            "foivAccept": row.foivAccept,
            "regionAccept": true
          }
        });
        if (res.status === 200 && res.data) {
          await this.getAll();
          console.log('accept успех');
          this.dialog = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style scoped>

</style>