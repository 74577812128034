<template>
  <div>
    <header class="section-header section-preview__header">
      <h2 class="section-header__title">
        Согласование количества мест, необходимых для приема на целевое обучение
      </h2>
    </header>
    <wrapper-preview-category customClass="section-preview_lk">
      <GridTable
        :data-rows="approvalData"
        :headers="tableHeaders.massive"
        :headers-for-rows="headersForRows"
        :num-cols="tableHeaders.countCells"
        :loading-data="loading"
        max-table-height="700px">
        <template #nps="{ row }">
          <div>{{ `(${row.sprSpec.specCode}) ${row.sprSpec.specName}` }}</div>
        </template>
        <!--        <template #subject="{ row }">-->
        <!--          <div>{{ row.sprRegion.regionName }}</div>-->
        <!--        </template>-->
        <template #subject="{ row }">
          <div>{{ subject(row.sprRegion) }}</div>
        </template>
        <template #placesOchno="{ row }">
          <div>{{ row.placesOchno }}</div>
        </template>
        <template #placesOcnhoZaochno="{ row }">
          <div>{{ row.placesOcnhoZaochno }}</div>
        </template>
        <template #placesZaochno="{ row }">
          <div>{{ row.placesZaochno }}</div>
        </template>
        <template #placesOchnoFoiv="{ row }">
          <div>{{ row.foivOchno }}</div>
        </template>
        <template #placesOcnhoZaochnoFoiv="{ row }">
          <div>{{ row.foivOchnoZochno }}</div>
        </template>
        <template #placesZaochnoFoiv="{ row }">
          <div>{{ row.foivZaochno }}</div>
        </template>
        <template #finalApprove="{ row }">
          <v-icon v-if="row.foivAccept && row.regionAccept" color="primary" size="24"
            >mdi-check-bold
          </v-icon>
        </template>
        <template #approval="{ row }">
          <v-btn
            v-if="!row.foivAccept && mainTest(row.sprSpec.sprUgs.ugsId)"
            color="primary"
            dark
            @click="accept(row)">
            Согласовать
            <v-icon dark right>mdi-check</v-icon>
          </v-btn>
          <v-btn v-if="row.foivAccept && mainTest(row.sprSpec.sprUgs.ugsId)" color="info" dark>
            Согласовано
            <v-icon dark right>mdi-check-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="row.foivAccept && mainTest(row.sprSpec.sprUgs.ugsId)"
            color="error"
            dark
            @click="cancel(row)">
            Отменить
            <v-icon dark right>mdi-check</v-icon>
          </v-btn>
          <!-- <v-hover>
            <v-btn
              v-if="row.foivAccept && mainTest(row.sprSpec.sprUgs.ugsId)"
              :color="isHovered ? 'primary' : 'info'"
              dark
              @click="cancel(row)"
              @mouseover="isHovered = true"
              @mouseleave="isHovered = false">
              {{ isHovered ? 'Отменить' : 'Согласовано' }}
              <v-icon dark right>mdi-check-outline</v-icon>
            </v-btn>
          </v-hover> -->
          <v-btn
            v-if="!row.foivAccept && mainTest(row.sprSpec.sprUgs.ugsId)"
            @click="editDialogOpen(row)"
            color="primary"
            dark
            style="margin-top: 10px">
            Изменить
            <v-icon dark right>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </GridTable>
      <v-dialog v-model="dialog" width="1300">
        <v-card>
          <v-card-title> Изменение информации </v-card-title>
          <!--  @change="getSelectorFo"-->
          <!--          <v-autocomplete-->
          <!--            v-model="foivDataRow.spec"-->
          <!--            disabled-->
          <!--            :items="specData"-->
          <!--            label="НПС"-->
          <!--          ></v-autocomplete>-->
          <v-text-field v-model="foivDataRow.specName" disabled label="НПС"></v-text-field>
          <v-text-field
            v-model="foivDataRow.placesOchno"
            disabled
            label="Количество очных мест"
            type="number"></v-text-field>
          <v-text-field
            v-model="foivDataRow.placesOcnhoZaochno"
            disabled
            label="Количество очно-заочных мест"
            type="number"></v-text-field>
          <v-text-field
            v-model="foivDataRow.placesZaochno"
            disabled
            label="Количество заочных мест"
            type="number"></v-text-field>
          <v-text-field
            v-model="foivDataRow.foivOchno"
            label="Количество очных мест (ФОИВ)"
            type="number"></v-text-field>
          <v-text-field
            v-model="foivDataRow.foivOchnoZochno"
            label="Количество очно-заочных мест (ФОИВ)"
            type="number"></v-text-field>
          <v-text-field
            v-model="foivDataRow.foivZaochno"
            label="Количество заочных мест (ФОИВ)"
            type="number"></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="dialog-btn" @click="editTableRow">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </wrapper-preview-category>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import request from '@/services/request';
  import WrapperPreviewCategory from '@/components/GridTable/dataFolder/WrapperPreviewCategory';
  import GridTable from '@/components/GridTable/GridTable';
  import ApprovalDataHeaders from '@/components/GridTable/dataFolder/ApprovalDataHeaders';

  export default {
    name: 'ApprovalFoiv',
    components: {
      GridTable,
      WrapperPreviewCategory,
    },
    data: () => ({
      isHovered: false,
      dialog: false,
      tableHeaders: [],
      approvalData: [],
      // showAppealDialog: false,
      // loadingAccepEnterData: false,
      isMain: null,
      loading: false,
      regionSelectorArray: [],
      foivDataRow: {
        id: null,
        spec: null,
        specName: null,
        placesOchno: null,
        placesOcnhoZaochno: null,
        placesZaochno: null,
        foivOchno: null,
        foivOchnoZochno: null,
        foivZaochno: null,
      },
    }),
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        getUser: 'getUser',
        getUserRoles: 'getUserRoles',
        isRegion: 'isRegion',
      }),
      ...mapGetters('spr', { get_spr_data_spec: 'get_spr_data_spec' }),
      // specData(){
      //   return
      // },
      headersForRows() {
        return this.tableHeaders.massive
          .filter((el) => el.variable)
          .sort((a, b) => a.order - b.order);
      },
    },
    created() {
      this.tableHeaders = ApprovalDataHeaders(this);
      this.getByFounder();
      this.getAllSprRegion();
      this.getAll();
    },
    methods: {
      // async clickBlockEnterData(obj) {
      //   this.loadingBlockEnterData = true;
      //   const agree = confirm('После потверждения вы не сможете менять данные.');
      //   if (agree) {
      //     const res = await request({
      //       endpoint: `/api/block/for/print?userId=${obj.userId}`,
      //       method: 'get'
      //     })
      //     if (res.status === 200) {
      //       await this.blockEnterData(res.data);
      //     } else {
      //       await this.addNotification({
      //         notificationStatus: 'error',
      //         notificationMessage: 'Ошибка блокировки данных',
      //         timeout: 5000
      //       })
      //     }
      //   }
      //   this.loadingBlockEnterData = false;
      // },

      ...mapActions('notifications', { addNotification: 'addNotification' }),

      // async actionRow(obj) {
      //   this.loadingAccepEnterData = true;
      //   const agree = confirm('Вы принимаеме значения указанные ФОИВом? После подтверждения отменить нельзя');
      //   if (agree) {
      //     const res = await request({
      //       endpoint: `/api/collection/main/update/byFoiv/accept`,
      //       method: 'post',
      //       body: {
      //         regionAccept: true,
      //         foivAccept: true,
      //         collectionId: obj.collectionId
      //       }
      //     })
      //     if (res.status === 200) {
      //       this.getDataForTable({
      //         params: '?regionId=' + this.getRegionId,
      //         link: this.headers.endPointForSelect,
      //         allValues: true,
      //         pageNumber: this.idPageDefault,
      //         recordCount: this.numPageDefault
      //       })
      //     } else {
      //       await this.addNotification({
      //         notificationStatus: 'error',
      //         notificationMessage: 'Ошибка выполнения команды',
      //         timeout: 5000
      //       })
      //     }
      //   }
      //   this.loadingAccepEnterData = false;
      // },

      async getAll() {
        try {
          const res = await request({
            endpoint: `/api/collection/region/getAll?founderId=${this.getUser.founderID}`,
            method: 'get',
            token: `${this.getAuthData.token}`,
          });
          if (res.status === 200 && res.data) {
            console.log('getAll успех');
            this.approvalData = res.data;
          }
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      },
      async getByFounder() {
        this.loading = true;
        try {
          const res = await request({
            endpoint: `/api/spr/founder/founderId?founderId=${this.getUser.founderID}`,
            method: 'get',
            token: `${this.getAuthData.token}`,
          });
          if (res.status === 200 && res.data) {
            console.log('getByFounder успех');
            this.isMain = res.data.sprCoUgs.filter((el) => el.isMain === true);
          }
        } catch (e) {
          console.log(e);
        }
      },
      mainTest(ugsId) {
        if (this.isMain.find((el) => el.sprUgs?.ugsId === ugsId)) {
          return true;
        }
      },
      async accept(row) {
        try {
          const res = await request({
            endpoint: `/api/collection/main/update/byFoiv/accept`,
            method: 'post',
            token: `${this.getAuthData.token}`,
            body: {
              collectionId: row.collectionId,
              regionId: row.sprRegion.regionId,
              specId: row.sprSpec.specId,
              placesOchno: row.placesOchno,
              placesZaochno: row.placesZaochno,
              placesOcnhoZaochno: row.placesOcnhoZaochno,
              foivOchno: row.foivOchno,
              foivOchnoZochno: row.foivOchnoZochno,
              foivZaochno: row.foivZaochno,
              foivAccept: true,
              regionAccept: row.regionAccept,
            },
          });
          if (res.status === 200 && res.data) {
            await this.getAll();
            console.log('accept успех');
            this.dialog = false;
          }
        } catch (e) {
          console.log(e);
        }
      },
      async cancel(row) {
        try {
          const res = await request({
            endpoint: `/api/collection/main/update/byFoiv/accept`,
            method: 'post',
            token: `${this.getAuthData.token}`,
            body: {
              collectionId: row.collectionId,
              regionId: row.sprRegion.regionId,
              specId: row.sprSpec.specId,
              placesOchno: row.placesOchno,
              placesZaochno: row.placesZaochno,
              placesOcnhoZaochno: row.placesOcnhoZaochno,
              foivOchno: row.foivOchno,
              foivOchnoZochno: row.foivOchnoZochno,
              foivZaochno: row.foivZaochno,
              foivAccept: false,
              regionAccept: row.regionAccept,
            },
          });
          if (res.status === 200 && res.data) {
            await this.getAll();
            console.log('cancel успех');
            this.dialog = false;
          }
        } catch (e) {
          console.log(e);
        }
      },
      editDialogOpen(row) {
        this.dialog = true;
        this.foivDataRow.id = row.collectionId;
        this.foivDataRow.spec = row.sprSpec.specId;
        this.foivDataRow.specName = `${row.sprSpec?.specCode} ${row.sprSpec?.specName}`;
        this.foivDataRow.placesOchno = row.placesOchno;
        this.foivDataRow.placesOcnhoZaochno = row.placesOcnhoZaochno;
        this.foivDataRow.placesZaochno = row.placesZaochno;
        this.foivDataRow.foivOchno = row.foivOchno;
        this.foivDataRow.foivOchnoZochno = row.foivOchnoZochno;
        this.foivDataRow.foivZaochno = row.foivZaochno;
      },
      async editTableRow() {
        try {
          const res = await request({
            endpoint: `api/collection/main/update/byFoiv`,
            method: 'post',
            token: `${this.getAuthData.token}`,
            body: {
              collectionId: this.foivDataRow.id,
              founderId: this.getUser.founderID,
              specId: this.foivDataRow.spec,
              placesOchno: this.foivDataRow.placesOchno,
              placesOcnhoZaochno: this.foivDataRow.placesOcnhoZaochno,
              placesZaochno: this.foivDataRow.placesZaochno,
              foivOchno: this.foivDataRow.foivOchno,
              foivOchnoZochno: this.foivDataRow.foivOchnoZochno,
              foivZaochno: this.foivDataRow.foivZaochno,
            },
          });
          if (res.status === 200 && res.data) {
            await this.getAll();
            console.log('editTableRow успех');
            this.dialog = false;
          }
        } catch (e) {
          console.log(e);
        }
      },
      async getAllSprRegion() {
        try {
          const res = await request({
            endpoint: `/api/spr/region/getAll`,
            method: 'get',
            token: `${this.getAuthData.token}`,
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].regionName && res.data[i].regionId) {
                this.regionSelectorArray.push({
                  text: res.data[i].regionName,
                  value: res.data[i].regionId,
                });
              }
            }
            console.log('getAllSprRegion успех');
            // await this.getUserId();
          }
        } catch (e) {
          console.log(e);
        }
      },
      subject(id) {
        const x = this.regionSelectorArray.find(
          (el) => el.value === id || el.value === id.regionId,
        );
        return x?.text;
      },
    },
  };
</script>

<style lang="sass">
  .yellow-background
    background-color: #fff3cd
    color: #D4990A

  .section-preview__header
    font-size: 24px
    font-weight: bold
    margin: 16px 0
    color: #334D6E

  .dialog-btn
    .v-btn__content
      padding: 10px 5px

  .v-autocomplete
    padding: 0 24px

  .v-text-field
    padding: 0 24px

  .mdi-btns
    align-self: start
</style>
