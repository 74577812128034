<template>
  <div>
    <ApprovalRegion v-if="getUserRoles.find(el => el === 'REGION_ROLE')"></ApprovalRegion>
    <ApprovalFoiv v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE')"></ApprovalFoiv>
    <!--    <div v-else>-->
    <!--      <p>Ошибка модуля заявки</p>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ApprovalRegion from "@/views/private/regionData/ApprovalRegion";
import ApprovalFoiv from "@/views/private/foivData/ApprovalFoiv";

export default {
  name: "ApprovalView",
  components: {ApprovalRegion, ApprovalFoiv},
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
  },
}
</script>

<style scoped>

</style>